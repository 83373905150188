import SuggestionsContainer from "./suggestions-container/suggestions-container.component";
import { useQuery } from "@tanstack/react-query";
import { fetchHome } from "../../utils/api/home";
import { setIsLoading } from "../../store/loading/loadingSlice";
import { useAppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import { lazy, Suspense } from "react";
import Navbar from "../navbar/navbar.component";
import Loader from "../loader/loader.component";
import AOS from "aos";
import "aos/dist/aos.css";

import { Helmet } from "react-helmet-async";


let firstLoad = true;
export default function Home() {
	const [loadRest, setLoadRest] = useState(false);
	const dispatch = useAppDispatch();
	const homeData = useQuery({
		queryKey: ["home"],
		queryFn: fetchHome,
	});
	useEffect(() => {
		AOS.init({
			once: true,
		});
	}, []);
	useEffect(() => {
		if (homeData.isLoading) {
			dispatch(setIsLoading(true));
		} else {
			dispatch(setIsLoading(false));
		}
	}, [dispatch, homeData.isLoading]);
	if (homeData.isLoading) return <Loader />;
	if (homeData.isError) return <div>Error..</div>;

	if (!firstLoad) {
		const HomeRest = lazy(() => import("./homeRest.component"));
		const Footer = lazy(() => import("../footer/footer.component"));
		return (
			<>
				<Helmet>
					<title>ResultGate</title>
					<meta name="description" content="Hello World" />
				</Helmet>
				<header
					className="flex justify-center w-full fixed top-0 bg-white shadow-sm "
					style={{ zIndex: 1100 }}
				>
					<Navbar />
				</header>
				<main className="container grow px-4">
					<div className="container mx-auto flex flex-col gap-10 max-w-full mt-8">
						<SuggestionsContainer
							suggestionsData={{
								first_ad: homeData.data.first_ad,
								second_ad: homeData.data.second_ad,
								center_ads: homeData.data.center_ads,
							}}
						/>
					</div>
				</main>
				<Suspense>
					<main className="container grow overflow-hidden px-4 min-h-screen">
						<div className="container mx-auto flex flex-col gap-10 max-w-full mt-8">
							<HomeRest homeData={homeData.data} />
						</div>
					</main>
					<Footer />
				</Suspense>
			</>
		);
	} else {
		setTimeout(() => {
			firstLoad = false;
			setLoadRest(true);
		}, 1000);
		return (
			<>
				<header className="flex justify-center w-full fixed top-0 bg-white z-40 shadow-sm">
					<Navbar />
				</header>
				<main className="container grow px-4 min-h-screen">
					<div className="container mx-auto flex flex-col gap-10 max-w-full mt-8">
						<SuggestionsContainer
							suggestionsData={{
								first_ad: homeData.data.first_ad,
								second_ad: homeData.data.second_ad,
								center_ads: homeData.data.center_ads,
							}}
						/>
					</div>
				</main>
			</>
		);
	}
}
