import RecomendationCard from "../recomendation-card/recomendation-card.component";
import SuggestionCardLarg from "../suggestion-card-larg/suggestion-card-larg.component";
import SuggestionCardMini from "../suggestion-card-mini/suggestion-card-mini.component";
import { type IcardData } from "../recomendation-card/recomendation-card.component";
interface SuggestionsContainerProps {
	suggestionsData: {
		first_ad: IcardData;
		second_ad: IcardData;
		center_ads: IcardData[];
	};
}
export default function SuggestionsContainer({
	suggestionsData,
}: SuggestionsContainerProps) {
	const { first_ad, second_ad, center_ads } = suggestionsData;
	return (
		<div className="grid grid-cols-12 grid-rows-16 gap-6 container max-w-full overflow-hidden">
			<RecomendationCard cardData={first_ad} />
			<SuggestionCardLarg cardData={second_ad} />
			{center_ads.length === 4 &&
				center_ads.map((cardData, idx) => (
					<SuggestionCardMini key={idx} cardData={cardData} />
				))}
		</div>
	);
}
