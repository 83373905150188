const Theme = {
	TextPrimary: "#4DABE2",
	// TextPrimary: "#E30613",
	TextSecondary: "#232323",
	TextTertiary: "#1450A3",
	BorderPrimary: "#CFCFCF",
	BackgroundPrimary: "#ffffff",
	BackgroundSecondary: "#F9F9F9",
	SecondaryColor: "#FFC436",
	inactiveColor: "#AEA9A9",
	checkoutBackground: "#E9E9E9",
	inputColor: "#DFDFDF",
	profileBackground: "#efefef",
	green: "#1FAF38",
	footerColor: "#232323",
};
export default Theme;
