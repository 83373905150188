import { CirclesWithBar } from "react-loader-spinner";
import Theme from "../../utils/stylesTheme";
import { useAppSelector } from "../../store/store";
export default function Loader() {
	const isLoading = useAppSelector((state) => state.loading.isLoading);
	if (isLoading)
		return (
			<div
				className="w-full h-full flex items-center justify-center fixed top-0 left-0 right-0 bottom-0"
				style={{ background: Theme.BackgroundPrimary, zIndex: 2000 }}
			>
				<CirclesWithBar
					height="300"
					width="300"
					outerCircleColor={Theme.TextSecondary}
					innerCircleColor={Theme.green}
					barColor={Theme.TextPrimary}
					ariaLabel="circles-with-bar-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/>
			</div>
		);
	else return <div></div>;
}
