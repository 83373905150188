import "./App.css";
import { Route, Routes } from "react-router-dom";

import Navigation from "./routes/navigation/navigation.route";
import { lazy } from "react";
import Theme from "./utils/stylesTheme";
import { useAppDispatch, useAppSelector } from "./store/store";
import axios from "axios";
import axiosRetry from "axios-retry";
import Home from "./routes/home/home.route";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import i18n from "./utils/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { deleteUser } from "./store/user/userSlice";
import ReactGA from "react-ga4";

// const Home = lazy(() => import("./routes/home/home.route"));
const Login = lazy(() => import("./routes/login/login.route"));
const ForgetPassword = lazy(
	() => import("./routes/forget-password/forget-password.route")
);
const ForgetPasswordCode = lazy(
	() => import("./routes/forget-password-code/forget-password-code.route")
);
const RestPassword = lazy(
	() => import("./routes/reset-password/reset-password.route")
);
const SignUp = lazy(() => import("./routes/sign-up/sign-up.route"));
const Products = lazy(() => import("./routes/products/products.route"));
const Devices = lazy(() => import("./routes/devices/devices.route"));
const Product = lazy(() => import("./routes/product/product.route"));
const Cart = lazy(() => import("./routes/cart/cart.route"));
const NewAddress = lazy(() => import("./routes/new-address/new-address.route"));
const PaymentMethod = lazy(
	() => import("./routes/payment-method/payment-method.route")
);
const Checkout = lazy(() => import("./routes/checkout/checkout.route"));
const ThankYou = lazy(() => import("./routes/thank-you/thank-you.route"));
const Invoice = lazy(() => import("./routes/invoice/invoice.route"));
const Teams = lazy(() => import("./routes/teams/teams.route"));
const Team = lazy(() => import("./routes/team/team.route"));
const Blogs = lazy(() => import("./routes/blogs/blogs.route"));
const Blog = lazy(() => import("./routes/blog/blog.route"));
const ContactUs = lazy(() => import("./routes/contact-us/contact-us.route"));
const TermsAndConditions = lazy(
	() => import("./routes/term-and-conditions/terms-and-conditions.route")
);
const AboutUs = lazy(() => import("./routes/about-us/about-us.route"));
const PrivacyPolicy = lazy(
	() => import("./routes/privacy-policy/privacy-policy.route")
);
const FrequentlyQuestions = lazy(
	() => import("./routes/frequently-questions/frequently-questions.route")
);
const PaymentPolicy = lazy(
	() => import("./routes/payment-policy/payment-policy.route")
);
const Profile = lazy(() => import("./routes/profile/profile.route"));
const AccountInformation = lazy(
	() => import("./routes/account-information/account-information.route")
);
const MyOrders = lazy(() => import("./routes/my-orders/my-orders.route"));
const MyAddresses = lazy(
	() => import("./routes/my-addresses/my-addresses.route")
);
const Wishlist = lazy(() => import("./routes/wishlist/wishlist.route"));
const Notifications = lazy(
	() => import("./routes/notifications/notifications.route")
);
const Error404 = lazy(() => import("./routes/error-404/error-404.route"));
const EmailVerification = lazy(
	() => import("./routes/email-verification/email-verification.route")
);

function App() {
	const user = useAppSelector((state) => state.user.userData);

	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	axiosRetry(axios, { retries: 3 });

	axios.interceptors.response.use((response)=>{
		if(response.status === 401){
			localStorage.clear();
			queryClient.clear();
			dispatch(deleteUser());
		}
		return response;
	}, (error)=>{
		if(error.response.status === 401){
			localStorage.clear();
			queryClient.clear();
			dispatch(deleteUser());
		}
		return Promise.reject(error);
	});


	if (user) axios.defaults.headers.common["Authorization"] = user.token;
	document.dir = i18n.dir();
	axios.defaults.headers.common["lang"] = i18n.language;

	ReactGA.initialize("G-Y081LEDNSZ");



	return (
		<div
			className="App flex flex-col items-center relative"
			style={{ color: Theme.TextSecondary }}
		>
			<ReactNotifications />
			<Routes>
				<Route path="/home" element={<Home />} />
				<Route path="/" element={<Navigation />}>
					<Route path="/login" element={<Login />} />
					<Route path="/forget-password" element={<ForgetPassword />} />
					<Route
						path="/forget-password-code/:email"
						element={<ForgetPasswordCode />}
					/>
					<Route path="/reset-password/:email" element={<RestPassword />} />
					<Route path="/sign-up" element={<SignUp />} />
					<Route
						path="/email-verification/:email/:userData"
						element={<EmailVerification />}
					/>
					<Route path="/products" element={<Products />} />
					<Route path="/products/:productsData" element={<Products />} />
					<Route path="/devices" element={<Devices />} />
					<Route path="/devices/:devicesData" element={<Devices />} />
					<Route path="/product/:productId" element={<Product />} />
					<Route path="/payment-method" element={<PaymentMethod />} />
					<Route path="/thank-you" element={<ThankYou />} />
					<Route path="/teams" element={<Teams />} />
					<Route path="/teams/:teamsData" element={<Teams />} />
					<Route path="/team/:teamId" element={<Team />} />
					<Route path="/blogs" element={<Blogs />} />
					<Route path="/blog/:blogId" element={<Blog />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route
						path="/terms-and-conditions"
						element={<TermsAndConditions />}
					/>
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/payment-policy" element={<PaymentPolicy />} />
					<Route
						path="/frequently-questions"
						element={<FrequentlyQuestions />}
					/>
					{user && (
						<>
							<Route path="/cart" element={<Cart />} />
							<Route path="/checkout" element={<Checkout />} />
							<Route path="/new-address" element={<NewAddress />} />
							<Route path="/invoice/:orderNumber" element={<Invoice />} />
							<Route path="/profile" element={<Profile />}>
								<Route index element={<AccountInformation />} />
								<Route
									path="/profile/account-information"
									element={<AccountInformation />}
								/>
								<Route path="/profile/my-orders" element={<MyOrders />} />
								<Route path="/profile/my-addresses" element={<MyAddresses />} />
								<Route path="/profile/wishlist" element={<Wishlist />} />
								<Route
									path="/profile/notifications"
									element={<Notifications />}
								/>
							</Route>
						</>
					)}

					<Route path="*" element={<Error404 />} />
				</Route>
			</Routes>
		</div>
	);
}

export default App;
