import Theme from "../../../utils/stylesTheme";
import Notifications from "./notifications/notifications.component";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { IconButton } from "@mui/material";
import CartItems from "./cart-items/cart-items.component";
import Profile from "./profile/profile.component";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

interface props {
	setShow: (value: boolean) => void;
	tabNumber?: number;
}
export default function NavMenu({ setShow, tabNumber = 0 }: props) {
	const Tabs = [<Notifications />, <CartItems />, <Profile />];
	const { t } = useTranslation();

	return (
		<>
			<div
				className="fixed top-0 left-0 h-screen w-screen"
				onClick={() => setShow(false)}
			></div>
			<div
				className={`flex flex-row-reverse absolute top-full ${t(
					"revers-lang-direction"
				)}-0`}
			>
				<div
					className="flex flex-col items-center shadow-xl rounded-xl border-2 border-solid p-2"
					style={{
						borderColor: Theme.BorderPrimary,
						backgroundColor: Theme.BackgroundPrimary,
					}}
				>
					<motion.div whileHover={{ rotate: 360 }} className="self-start">
						<IconButton
							onClick={() => {
								setShow(false);
							}}
							sx={{
								width: "40px",
								height: "40px",
								"&:hover": { color: Theme.TextPrimary },
							}}
						>
							<ClearOutlinedIcon fontSize="large" />
						</IconButton>
					</motion.div>
					{Tabs[tabNumber]}
				</div>
			</div>
		</>
	);
}
