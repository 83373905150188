import { FallingLines } from "react-loader-spinner";
import Theme from "../../../utils/stylesTheme";

export default function Loader() {
	return (
		<div className="max-w-full max-h-full flex justify-center items-center">
			<FallingLines color={Theme.TextPrimary} visible={true} />
		</div>
	);
}
