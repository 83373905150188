import { combineReducers } from "@reduxjs/toolkit";
import TeamsReducer from "./teams/teamsSlice";
import TeamReducer from "./team/teamSlice";
import AboutUsSlice from "./about-us/about-usSlice";
import BlogsSlice from "./blogs/blogsSlice";
import userSlice from "./user/userSlice";
import loadingSlice from "./loading/loadingSlice";
import popoverSlice from "./popover/popoverSlice";
import cartSlice from "./cart/cartSlice";
import searchSlice from "./search/searchSlice";

const rootReducer = combineReducers({
	// aboutUs: AboutUsSlice,
	// blogs: BlogsSlice,
	// teams: TeamsReducer,
	// team: TeamReducer,
	user: userSlice,
	loading: loadingSlice,
	// popover: popoverSlice,
	cart: cartSlice,
	// search: searchSlice,
});

export default rootReducer;
