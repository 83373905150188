import { styled } from "styled-components";
import CardBackground from "../../../assets/images/card-background.svg";
import Theme from "../../../utils/stylesTheme";

export const ProductContainer = styled.a`
	background-image: url(${CardBackground});
	background-size: cover;
`;
export const OldPrice = styled.span`
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: ${Theme.TextPrimary};
		transform: translateY(-50%);
	}
`;
