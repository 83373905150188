import Navbar from "../../components/navbar/navbar.component";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { lazy } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import MiniLoader from "../../components/global/loader/loader.component";
import Loader from "../../components/loader/loader.component";

const Footer = lazy(() => import("../../components/footer/footer.component"));

export default function Navigation() {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		if (pathname === "/") navigate("/home");
	}, [navigate, pathname]);
	return (
		<>
			<Suspense fallback={<MiniLoader />}>
				<header className="flex justify-center w-full fixed top-0 bg-white z-40 shadow-sm no-print">
					<Navbar />
				</header>
				<main className="container grow px-4">
					<Outlet />
				</main>
				<Footer />
			</Suspense>

			<Loader />
		</>
	);
}
