import { styled } from "styled-components";
import defaultStyles from "../../../style/defaultStyles";
import Theme from "../../../utils/stylesTheme";

export const DropdownContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 140px;
	margin: 10px;
	z-index: 1000;
	.dropbtn {
		padding: 0 6px;
		font-size: 14px;
		font-weight: bold;
		height: 20px;
		border: none;
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		width: 100%;
		top: 20px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	}

	.dropdown-content a {
		color: black;
		padding: 6px;
		text-decoration: none;
		display: block;
	}

	.dropdown-content a:hover {
		background-color: #f1f1f1;
	}

	&:hover .dropdown-content {
		display: block;
		border-radius: 0 0 10px 10px;
	}

	&:hover {
		.dropbtn {
			background-color: ${defaultStyles.backgroundColor};
			border-radius: 5px 5px 0 0;
			img:last-of-type {
				transform: rotate(180deg);
			}
		}
		.dropdown-content {
			display: block;
			border-radius: 0 0 5px 5px;
		}
	}
`;
