import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Theme from "../../../utils/stylesTheme";

const links = [
	{
		title: "Home",
		to: "/home",
	},
	{
		title: "Teams",
		to: "/teams",
	},
	{
		title: "Products",
		to: "/products",
	},
	{
		title: "Devices",
		to: "/devices",
	},
	{
		title: "Blogs",
		to: "/blogs",
	},
	{
		title: "About",
		to: "/about-us",
	},
];
export default function NavigationLinks() {
	const { t } = useTranslation();
	const currentPath = "/" + useLocation().pathname.split("/")[1];
	return (
		<ul className="hidden xl:flex justify-evenly font-semibold gap-5 text-lg">
			{links.map(({ title, to }) => (
				<motion.li
					whileHover={{ scale: 1.1, color: Theme.TextPrimary }}
					key={to}
				>
					{currentPath === to ? (
						<Link
							className="font-bold"
							style={{
								color:
									currentPath === to ? Theme.TextPrimary : Theme.TextSecondary,
							}}
							to={to}
						>
							{t(title)}
						</Link>
					) : (
						<Link to={to}>{t(title)}</Link>
					)}
				</motion.li>
			))}
		</ul>
	);
}
