import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Theme from "../../../utils/stylesTheme";
import { useTranslation } from "react-i18next";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Drawer,
	IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import i18n from "../../../utils/i18n";
import { inherits } from "util";

const links = [
	{
		title: "Products",
		to: "/products",
	},
	{
		title: "Devices",
		to: "/devices",
	},
	{
		title: "Teams",
		to: "/teams",
	},
	{
		title: "Profile",
		to: "/profile",
	},
	{
		title: "Blogs",
		to: "/blogs",
	},
	{
		title: "Contact",
		to: "/contact-us",
	},
	{
		title: "About",
		to: "/about-us",
	},
];
interface Iprops {
	categories: { title: string; id: number }[];
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}
export default function SideMenu({ categories, isOpen, setIsOpen }: Iprops) {
	const toggleDrawer = (newOpen: boolean) => () => {
		setIsOpen(newOpen);
	};

	const direction = i18n.language === "ar" ? "right" : "left";
	const { t } = useTranslation();
	return (
		<Drawer
			sx={{ backgroundColor: "transparent" }}
			open={isOpen}
			anchor={direction}
			onClose={toggleDrawer(false)}
		>
			<div
				className="h-full flex flex-col justify-center overflow-y-auto overflow-hidden"
				style={{
					backgroundColor: Theme.BackgroundPrimary,
				}}
			>
				<motion.div
					className="self-end"
					whileHover={{ rotate: 360, color: Theme.TextPrimary }}
				>
					<IconButton
						sx={{
							color: "inherit",
						}}
						aria-label="delete"
						size="small"
						onClick={toggleDrawer(false)}
					>
						<CloseIcon fontSize="large" />
					</IconButton>
				</motion.div>

				<div className="flex flex-col items-center justify-center">
					<motion.div
						className="flex w-full"
						whileHover={{ scale: 1.2, color: Theme.TextPrimary }}
					>
						<Link
							className="h-full w-full py-4 text-center font-semibold"
							to="/home"
						>
							{t("Home")}
						</Link>
					</motion.div>
					<Accordion defaultExpanded={false} sx={{}}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1-content"
							id="panel1-header"
						>
							<motion.h4
								whileHover={{ scale: 1.1 }}
								style={{ color: Theme.TextPrimary }}
								className="font-bold text-center w-full"
							>
								{t("Categories")}
							</motion.h4>
						</AccordionSummary>
						<AccordionDetails>
							<div className="flex flex-col min-w-40 w-full">
								{categories.map(
									({ title, id }: { title: string; id: number }) => (
										<motion.div
											key={id}
											className="flex w-full"
											initial={{ scale: 0.95 }}
											whileHover={{ scale: 1, color: Theme.TextPrimary }}
										>
											<Link
												className="h-full w-full py-4 "
												to={`/products/%7B"price_from":0,"price_to":0,"categories_ids":[${id}],"rate":0%7D`}
											>
												{title}
											</Link>
										</motion.div>
									)
								)}
							</div>
						</AccordionDetails>
					</Accordion>
					{links.map((link) => (
						<motion.div
							key={link.title}
							className="flex w-full"
							whileHover={{ scale: 1.2, color: Theme.TextPrimary }}
						>
							<Link
								className="h-full w-full py-4 text-center font-semibold"
								to={link.to}
							>
								{t(link.title)}
							</Link>
						</motion.div>
					))}
				</div>
			</div>
		</Drawer>
	);
}
