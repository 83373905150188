function formatPrice(price: number | string): string {
    // two decimal places
    price = (''+price).replace(',', '').replace('$', '').replace(',', '');
    const formattedPrice = Number(price).toFixed(0);
    if(isNaN(Number(price))) {
        return "0";
    }else{
        return formattedPrice;
    }
}


export default formatPrice;