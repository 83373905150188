import Logo from "../../../assets/images/header-logo.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { ReactComponent as VendorLogo } from "../../../assets/icons/become-verndor.svg";
import Language from "../language/language.component";
import { LogoContainer } from "./navbar-contact.style";
import Theme from "../../../utils/stylesTheme";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion, useAnimation } from "framer-motion";
import { Divider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchContact } from "../../../utils/api/contact";
import Loader from "../../global/loader/loader.component";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/store";
import { useEffect, useState } from "react";

export default function NavbarContact() {
	const control = useAnimation();
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		if (isScrolled) {
			control.start({ opacity: 0, height: 0 });
		} else {
			control.start({ opacity: 1, height: "auto" });
		}
	}, [isScrolled]);
	window.addEventListener("scroll", () => {
		if (window.scrollY < 100 && isScrolled) {
			setIsScrolled(false);
		} else if (window.scrollY > 200 && !isScrolled) {
			setIsScrolled(true);
		}
	});
	const { t } = useTranslation();
	const contacts = useQuery({
		queryKey: ["contacts"],
		queryFn: fetchContact,
	});
	const user = useAppSelector((state) => state.user.userData);
	if (contacts.isLoading) return <Loader />;
	if (contacts.isError) return <div>Error..</div>;
	const { email, phone } = contacts.data.data;
	return (
		<motion.div
			className={`flex justify-between w-full pt-4 `}
			animate={control}
		>
			<div className="flex items-center justify-center">
				<LogoContainer to="/home">
					<motion.div
						whileHover={{ scale: 1.1 }}
						className="w-28 h-28 flex items-center"
					>
						<LazyLoadImage
							className="max-w-full max-h-full"
							src={Logo}
							alt="logo"
						/>
					</motion.div>
				</LogoContainer>
				<div className="hidden xl:flex items-center justify-center">
					<motion.a
						initial={{ scale: 0.9 }}
						whileHover={{ scale: 1, color: Theme.TextPrimary }}
						href="/contact-us"
						className="ml-10 px-2 font-semibold"
					>
						{t("Contact Us")}
					</motion.a>
					<Divider
						orientation="vertical"
						sx={{ backgroundColor: Theme.TextSecondary }}
						flexItem
					/>
					<motion.a
						initial={{ scale: 0.9 }}
						whileHover={{ scale: 1, color: Theme.TextPrimary }}
						href={`mailto:${email}`}
						className="flex items-center gap-2 px-2 font-semibold"
					>
						<EmailOutlinedIcon />
						{email}
					</motion.a>
					<Divider
						orientation="vertical"
						sx={{ backgroundColor: Theme.TextSecondary }}
						flexItem
					/>
					<motion.a
						initial={{ scale: 0.9 }}
						whileHover={{ scale: 1, color: Theme.TextPrimary }}
						href={`tel:${phone}`}
						className="flex items-center gap-2 px-2 font-semibold"
					>
						<PhoneOutlinedIcon />
						{phone}
					</motion.a>
				</div>
			</div>
			<div className="flex items-center justify-center gap-3">
				<Language />
				{!Boolean(user) && (
					<Link
						to="/sign-up"
						className="flex items-center justify-center gap-5 p-3 rounded-xl font-semibold"
						style={{
							backgroundColor: Theme.TextPrimary,
							color: Theme.BackgroundPrimary,
						}}
					>
						<VendorLogo className="w-6 h-6" />
						<span className="hidden md:block">{t("BECOME A VENDOR")}</span>
					</Link>
				)}
			</div>
		</motion.div>
	);
}
