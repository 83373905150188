import axios from "axios";
import END_POINTS from "./api";

export const fetchNotifications = async () => {
	const response = await axios.get(END_POINTS.Notifications);
	return response.data;
};

export const deleteNotification = async (notification_id: number) => {
	const response = await axios.post(END_POINTS.DeleteNotification, {
		notification_id,
	});
	return response.data;
};

export const markNotificationAsSeen = async (id: number) => {
	const response = await axios.post(END_POINTS.NotificationsSeen, { id });
	return response.data;
};
