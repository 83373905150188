import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductImage from "../../../../assets/images/product2.png";
import Theme from "../../../../utils/stylesTheme";
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { IcartItem } from "../../../../types/cart";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCartItem } from "../../../../utils/api/cart";
import { Store } from "react-notifications-component";

let timeoutId: NodeJS.Timeout;
export default function CartItem({ cartData }: { cartData: IcartItem }) {
	const { t } = useTranslation();
	const [counter, setCounter] = useState(cartData.quantity);
	const queryClient = useQueryClient();
	const cartQuantityMutation: any = useMutation({
		mutationFn: ({
			cart_id,
			quantity,
		}: {
			cart_id: number;
			quantity: number;
		}) => updateCartItem({ cart_id, quantity }),
		onSuccess: (data) => {
			Store.addNotification({
				title: t("Cart"),
				message: data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
				},
			});
			queryClient.invalidateQueries({
				queryKey: ["cartItems"],
			});
		},
	});
	useEffect(() => {
		if (timeoutId) clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			if (counter !== cartData.quantity)
				cartQuantityMutation.mutate({
					cart_id: cartData.product_cart_id,
					quantity: counter,
				});
		}, 1000);
	}, [counter]);
	useEffect(() => {
		setCounter(cartData.quantity);
	}, [cartData.quantity]);

	return (
		<div
			className="flex gap-2 border-b-2 border-solid py-2"
			style={{ borderColor: Theme.BorderPrimary }}
		>
			<div className="flex items-center justify-center w-36 h-36">
				<LazyLoadImage
					src={cartData.primary_image_url}
					className="max-w-full max-h-full"
				/>
			</div>
			<div className="flex flex-col gap-3">
				<h3 className="text-lg font-semibold">{cartData.title}</h3>
				<div className="flex gap-4 items-center">
					<span
						className="text-lg font-bold"
						style={{ color: Theme.TextPrimary }}
					>
						{cartData.price} {t("EGP")}
					</span>
					{/* <span className="font-bold line-through">800 EGP</span> */}
				</div>
				<div className="flex justify-center items-center border-solid border-gray-200 border-2 shadow rounded-xl w-40 h-12 font-bold">
					<IconButton
						aria-label="remove"
						onClick={() => cartData.quantity > 1 && setCounter(counter - 1)}
					>
						<RemoveIcon />
					</IconButton>
					<span
						className="w-16 text-center"
						style={{ color: Theme.TextPrimary }}
					>
						{counter}
					</span>
					<IconButton aria-label="add" onClick={() => setCounter(counter + 1)}>
						<AddIcon />
					</IconButton>
				</div>
			</div>
		</div>
	);
}
