import { createSlice } from "@reduxjs/toolkit";
import { IcartData } from "../../types/cart";

const initialState: {
	cart: IcartData;
} = {
	cart: {
		promoCodeId: "",
		addressId: "",
	},
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		setCart: (state, action) => {
			state.cart = action.payload;
		},
		setPromoCodeId: (state, action) => {
			state.cart = {
				...state.cart,
				promoCodeId: action.payload,
			};
		},
		setAddressId: (state, action) => {
			state.cart = {
				...state.cart,
				addressId: action.payload,
			};
		},
		emptyCart: (state) => {
			emptyCart();
			state.cart = {
				promoCodeId: "",
				addressId: "",
			};
		},
	},
});

export const { setCart, setPromoCodeId, setAddressId, emptyCart } =
	cartSlice.actions;

export default cartSlice.reducer;
