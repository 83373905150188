import styles from './messsage.module.css';
import React, { useState } from 'react';

const Message = ({ content, user, isTyping }: {
    content: string;
    user: 'me' | 'user';
    isTyping?: boolean;
}) => (
  <div className={`message-content p-2 ${user === 'me' ? styles.messageMe : styles.messageUser}`}>
    {isTyping ? (
      <div className={styles.typingIndicator}>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </div>
    ) : (
      <>
        {user !== 'me' && (
          <div className="mr-1">
            <img src="/header-logo.png" alt="user avatar" className="rounded-circle max-w-10" />
          </div>
        )}

        <div dangerouslySetInnerHTML={{ __html: content }} />
      </>
    )}
  </div>
);


export default Message;