import { styled } from "styled-components";
import LabelBg from "../../../assets/images/label-background.svg";
export const LabelContainer = styled.div`
	display: block;
	background-image: url(${LabelBg});
	background-size: contain;
	background-repeat: no-repeat;
	span {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
		p {
			padding: 0 0.8rem;
		}
		svg {
			position: absolute;
			left: -10px;
			height: 100%;
		}
		&.red {
			p {
				background-color: red;
			}
			svg path {
				fill: red;
			}
		}
		&.yellow {
			p {
				color: black;
				background-color: #ffc436;
			}
			svg path {
				fill: #ffc436;
			}
		}
	}
`;
