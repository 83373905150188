import { styled } from "styled-components";
import Theme from "../../../utils/stylesTheme";

export const DropdownContainer = styled.div`
	position: relative;

	.dropdown-content {
		display: none;
		transition: opacity 0.4s;
		position: absolute;
		background-color: ${Theme.BackgroundPrimary};
		z-index: 1;
	}

	&.active .dropdown-content {
		display: block;
		opacity: 1;
		border-radius: 0 0 0.5rem 0.5rem;
	}
	.dropbtn [data-testid="ExpandMoreIcon"] {
		transition: transform 0.3s;
	}
	&.active {
		.dropbtn {
			background-color: ${Theme.TextPrimary};
			border-radius: 0.5rem 0.5rem 0 0;
			img:last-of-type {
				transform: rotate(180deg);
			}
			[data-testid="ExpandMoreIcon"] {
				transform: rotate(180deg);
			}
		}
	}
`;
