import { DropdownContainer } from "./dropdown.style";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Theme from "../../../utils/stylesTheme";
import { useQuery } from "@tanstack/react-query";
import { fetchCategories } from "../../../utils/api/categories";
import { Link } from "react-router-dom";
import SideMenu from "../side-menu/side-menu.component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { motion } from "framer-motion";
import Loader from "../../global/loader/loader.component";

const Dropdown = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const categories = useQuery({
		queryKey: ["categories"],
		queryFn: fetchCategories,
	});

	if (categories.isLoading) return <Loader />;
	if (categories.isError) return <div>Error..</div>;

	return (
		<DropdownContainer className={isOpen ? "active" : ""}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="hidden xl:flex dropbtn items-center justify-between gap-4 rounded-xl p-3 font-bold"
				style={{
					background: Theme.TextPrimary,
					color: Theme.BackgroundPrimary,
				}}
			>
				<FormatListBulletedOutlinedIcon />
				<span>{t("Browse Category")}</span>
				<ExpandMoreIcon fontSize="large" />
			</button>
			<button
				onClick={() => setOpen(!open)}
				className="flex xl:hidden dropbtn items-center justify-between gap-4 rounded-xl p-3 font-bold"
				style={{
					background: Theme.TextPrimary,
					color: Theme.BackgroundPrimary,
				}}
			>
				<FormatListBulletedOutlinedIcon />
			</button>
			<div
				className="flex flex-col min-w-40 w-full dropdown-content shadow-lg relative"
				style={{ zIndex: "1500" }}
			>
				{categories.data.data.map(
					({ title, id }: { title: string; id: number }) => (
						<motion.div
							key={id}
							className="flex w-full"
							initial={{ scale: 0.95 }}
							whileHover={{ scale: 1, color: Theme.TextPrimary }}
						>
							<Link
								className="h-full w-full py-4 text-center font-semibold"
								to={`/products/%7B"price_from":0,"price_to":0,"categories_ids":[${id}],"rate":0%7D`}
							>
								{title}
							</Link>
						</motion.div>
					)
				)}
			</div>
			<SideMenu
				categories={categories.data.data}
				isOpen={open}
				setIsOpen={setOpen}
			/>
		</DropdownContainer>
	);
};

export default Dropdown;
