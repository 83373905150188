import { Link, useNavigate } from "react-router-dom";
import Button from "../../../global/button/button.component";
import { Avatar } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Theme from "../../../../utils/stylesTheme";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { deleteUser } from "../../../../store/user/userSlice";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function Profile() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userData = useAppSelector((state) => state.user.userData);
	const queryClient = useQueryClient();

	return (
		<div className="flex flex-col gap-4 mt-3">
			<p className="text-center">
				{t("Hello")}, {userData.fname}
			</p>
			<Link to="/profile">
				<Button
					breath={false}
					type="square"
					addClass="justify-center items-center gap-2 w-full"
					style={{ padding: 8, backgroundColor: Theme.BorderPrimary }}
				>
					<Avatar style={{ width: 30, height: 30 }}>H</Avatar>{" "}
					<span>{t("My Settings")}</span>
				</Button>
			</Link>
			<Button
				onClick={() => {
					queryClient.clear();
					navigate("/home");
					dispatch(deleteUser());
				}}
				breath={false}
				type="square"
				addClass="justify-center items-center gap-2 w-full"
				style={{ padding: 8, backgroundColor: Theme.TextPrimary }}
			>
				<LogoutOutlinedIcon sx={{ transform: "rotate(180deg)" }} />{" "}
				<span>{t("Log Out")}</span>
			</Button>
		</div>
	);
}
