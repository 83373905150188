import React, { useState } from 'react';
import Message from '../message/message';
import { GoogleGenerativeAI } from "@google/generative-ai";


interface Message {
  text: string;
  isSender: boolean;
  isTyping: boolean;
}

const ChatBubble = () => {
  const [messages, setMessages] = useState<Message[]>([
    { text: "Welcome to ResultGate! Ask me anything about chemicals, and I'll guide you.", isSender: false, isTyping: false },
  ]);
  const [newMessage, setNewMessage] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false);

    const chatContainerRef = React.useRef<HTMLDivElement>(null);

  // Fetch your API_KEY (replace with your actual key)
  const API_KEY = "AIzaSyDx5DRSYS_QPv024L2Y-ZsNpanfF_A4vRA";
  const genAI = new GoogleGenerativeAI(API_KEY);

  const sendMessage = async (e: any) => {
    e.preventDefault();

    // Add user message to state with a typing indicator
    const userMessage = { text: newMessage, isSender: true, isTyping: false};
    setMessages([...messages, userMessage]);

    // Clear input field and simulate typing effect
    setNewMessage('');
    // await simulateTyping(message);

    // Generate AI response after a short delay
    setTimeout(async () => {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });

      const prompt = `I want your reply to be structured as it's a wed div, titles in <h3> and bold in <strong> etc, now Imagine you're a Website called ResultGate who understands all about chemicals  and answer that: "${newMessage}"`;
      const aiMessage = { text:"", isSender: false, isTyping: true };
      setMessages(prevMessages => [...prevMessages, aiMessage]);
      const result = await model.generateContent(prompt);
      const response = await result.response;

      // Add AI response to state with a typing indicator


      if (response) {
        const aiText = response.text();

        // Simulate AI typing effect
        // await simulateTyping(aiText);

        // Remove typing indicator from AI message and update state
        aiMessage.isTyping = false;
        aiMessage.text = aiText;
        setMessages(prevMessages => [...prevMessages.slice(0, -1), aiMessage]);
      } else {
        console.error('No response received from Gemini');
      }
    }, 0); // Adjust delay as needed
  };

  const handleToggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className="max-w-md mx-auto fixed right-4 bottom-4 ">
      {isChatOpen ? (
        <div className="p-4 bg-gray-100 rounded-md shadow-md">
          <div className="flex justify-between mb-4">
            <h2 className="text-lg font-bold">ResultGate Chat</h2>
            <button
              onClick={handleToggleChat}
              className="p-2 rounded-md bg-red-500 text-white hover:bg-red-700"
            >
              Close
            </button>
          </div>
          <div className="flex flex-col gap-4 overflow-y-auto min-h-96 max-h-96" ref={chatContainerRef}>
            {messages.map((message, index) => (
              <Message key={index} isTyping={message.isTyping} content={message.text} user={message.isSender ? 'me' : 'user'} />
            ))}
          </div>
          <div className="flex gap-2 mt-4">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              className="p-2 rounded-md bg-gray-300 w-full"
              placeholder="Type a message..."
            />
            <button
              onClick={sendMessage}
              className="p-2 rounded-md bg-[#4dabe2] text-white hover:bg-blue-700"
            >
              Send
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={handleToggleChat}
          className="p-4 rounded-full bg-[#4dabe2] text-white hover:bg-blue-700"
        >
          Chat with ResultGate
        </button>
      )}
    </div>
  );
};

export default ChatBubble;