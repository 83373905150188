import { Divider } from "@mui/material";
import Theme from "../../../../utils/stylesTheme";
import CartItem from "../cart-item/cart-item.component";
import Button from "../../../global/button/button.component";
import { useQuery } from "@tanstack/react-query";
import { fetchCart } from "../../../../utils/api/cart";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/store";
import { IcartItem } from "../../../../types/cart";
import { useTranslation } from "react-i18next";
import Loader from "../../../global/loader/loader.component";

export default function CartItems() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { promoCodeId, addressId } = useAppSelector((state) => state.cart.cart);
	const cartItems = useQuery({
		queryKey: ["cartItems"],
		queryFn: () =>
			fetchCart({ user_address_id: addressId, promo_code_id: promoCodeId }),
	});

	if (cartItems.isLoading) return <Loader />;
	if (cartItems.isError) return <div>Error..</div>;

	if (!cartItems.data.product.length)
		return (
			<div className="p-4 h-36 flex items-center justify-center font-semibold text-lg">
				<p>{t("No items in cart")}</p>
			</div>
		);
	return (
		<div className="flex flex-col gap-3 mt-2">
			<div className="flex flex-col gap-3 max-h-96 overflow-auto">
				{cartItems.data.product.map((product: IcartItem) => (
					<CartItem cartData={product} />
				))}
			</div>
			<div className="flex items-center justify-between flex-wrap">
				<span className="font-semibold">{t("Subtotal")}:</span>{" "}
				<span
					className="text-2xl font-bold"
					style={{ color: Theme.TextPrimary }}
				>
					{cartItems.data.sub_total} {t("EGP")}
				</span>
			</div>
			<Divider flexItem />
			<div className="flex items-center justify-between flex-wrap gap-2">
				<Button
					breath={false}
					type="square"
					addClass="h-10 items-center"
					style={{ backgroundColor: Theme.TextPrimary }}
					onClick={() => navigate("/cart")}
				>
					{t("View CART")}
				</Button>
				<Button
					breath={false}
					type="squareTransparent"
					addClass="h-10 items-center shadow"
					style={{ borderColor: Theme.BorderPrimary }}
					onClick={() => navigate("/checkout")}
				>
					{t("Checkout")}
				</Button>
			</div>
		</div>
	);
}
