import { useTranslation } from "react-i18next";
import Notification from "../notification/notification.component";
import { Link } from "react-router-dom";
import { fetchNotifications } from "../../../../utils/api/notifications";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../global/loader/loader.component";
import { Inotification } from "../../../../types/notification";
import { motion } from "framer-motion";
import Theme from "../../../../utils/stylesTheme";

export default function Notifications() {
	const { t } = useTranslation();
	const notificationsData = useQuery({
		queryKey: ["notifications"],
		queryFn: () => fetchNotifications(),
	});
	if (notificationsData.isLoading) return <Loader />;
	if (notificationsData.isError) return <div>Error..</div>;
	return (
		<div className="flex flex-col gap-2 min-w-80">
			{notificationsData.data.data
				.slice(0, 4)
				.map(({ id, image, title, body }: Inotification) => (
					<Notification key={id} title={title} content={body} image={image} />
				))}
			<motion.div
				whileHover={{
					scale: 1.1,
					color: Theme.TextPrimary,
				}}
				className="text-center font-semibold py-2"
			>
				<Link to="/profile/notifications">{t("See all notification")}</Link>
			</motion.div>
		</div>
	);
}
