import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	userData: null,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.userData = action.payload;
			axios.defaults.headers.common["Authorization"] = action.payload.token;
		},
		deleteUser: (state) => {
			state.userData = null;
			axios.defaults.headers.common["Authorization"] = "";
		},
	},
});

export const { setUser, deleteUser } = userSlice.actions;

export default userSlice.reducer;
