import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import axios from "axios";

i18n
	.use(LanguageDetector)
	.use(HttpApi)
	.use(initReactI18next)
	.init({
		debug: true,
		supportedLngs: ["en", "ar"],
		fallbackLng: "ar",
		detection: {
			order: [
				"querystring",
				"cookie",
				"localStorage",
				"sessionStorage",
				"navigator",
				"htmlTag",
				"path",
				"subdomain",
			],
			caches: ["cookie"],
			cookieMinutes: 10,
		},
		backend: {
			loadPath: "/locales/{{lng}}/translation.json",
		},
	});

export default i18n;
