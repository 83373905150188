import { styled } from "styled-components";
import Theme from "../../../utils/stylesTheme";

export const NotificationContainer = styled.div`
	button:not(:first-of-type) {
		border-left: 1px solid ${Theme.BorderPrimary};
	}
	button {
		padding: 0 1rem;
	}
`;
