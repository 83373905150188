import { Avatar } from "@mui/material";
import Theme from "../../../../utils/stylesTheme";

interface Props {
	title: string;
	content: string;
	image: string;
}

export default function Notification({ title, content, image }: Props) {
	return (
		<div className="flex items-center p-4 gap-4 max-w-96 border-b-2 border-solid">
			<Avatar sx={{ width: 60, height: 60 }} src={image} />
			<div>
				<h3 className="font-bold text-xl" style={{ color: Theme.TextPrimary }}>
					{title}
				</h3>
				<p className="max-h-12 text-ellipsis overflow-hidden ...">{content}</p>
			</div>
		</div>
	);
}
