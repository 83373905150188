import { motion } from "framer-motion";

const ButtonType = {
	round:
		"bg-red-600 text-white px-6 py-3 rounded-full mt-4 min-w-40 font-bold flex flex-row items-center justify-evenly lg:scale-110 3xl:scale-125 my-5",
	roundTransparent:
		"border border-solid text-sm font-semibold border-rose-500 text-black px-6 py-3 rounded-full min-w-40 flex flex-row items-center justify-evenly lg:scale-110 3xl:scale-125 my-5",
	square:
		"flex bg-red-600 text-white font-semibold p-4 rounded-lg justify-center",
	squareTransparent:
		"flex font-semibold p-4 rounded-lg border-solid border-2 justify-center",
};
type props = {
	type: "round" | "roundTransparent" | "square" | "squareTransparent";
	addClass?: string;
	children: React.ReactNode;
	style?: React.CSSProperties;
	breath?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	formType?: "button" | "submit" | "reset" | undefined;
};
export default function Button({
	type,
	addClass,
	children,
	style,
	onClick,
	breath = true,
	formType = "button",
	...otherProps
}: props) {
	return (
		<motion.button
			type={formType}
			initial={{ scale: breath ? 0.95 : 1 }}
			whileHover={{
				scale: breath ? 1 : 1.02,
				boxShadow: "0px 0px 10px rgb(116, 116, 116)",
				textShadow: "0px 0px 10px rgba(241, 241, 241, 0.614)",
			}}
			animate={
				breath && {
					scale: [1, 0.95, 1, 0.95, 1, 0.95, 1],
					transition: { repeat: Infinity, duration: 2, repeatDelay: 4 },
				}
			}
			whileTap={{ scale: 0.95 }}
			className={`${ButtonType[type]} ${addClass || ""}`}
			style={style}
			{...otherProps}
			onClick={onClick}
		>
			{children}
		</motion.button>
	);
}
