import Button from "../../global/button/button.component";
import { ProductContainer } from "./recomendation-card.style";
import { OldPrice } from "./recomendation-card.style";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Theme from "../../../utils/stylesTheme";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { useTranslation } from "react-i18next";
import formatPrice from "../../../utils/priceUtil";

export interface IcardData {
	big_text: string;
	link: string;
	main_image: string;
	small_text: string;
	real_price: string;
	fake_price: string;
	discount: string;
}
type RecomendationCardProps = {
	cardData: IcardData;
};
export default function RecomendationCard({
	cardData,
}: RecomendationCardProps) {
	const { t } = useTranslation();
	const { big_text, link, main_image, small_text, real_price, fake_price } =
		cardData;
	return (
		<ProductContainer
			href={link}
			className="font-semibold col-span-12 row-span-8 lg:row-span-12 xl:col-span-9 h-full lg:p-14 md:p-8 p-4 rounded-lg shadow-lg flex flex-col-reverse sm:flex-row justify-between items-center"
			// style={{ background: Theme.BackgroundPrimary }}
		>
			<div className="flex flex-col h-full justify-center gap-4 min-w-2/3 xl:w-1/2">
				<div>
					<div>
						<b className="text-xl">{t("Enjoy")}</b>
						<h2
							className="text-xl s:text-3xl xl:text-4xl font-bold"
							style={{ color: Theme.TextPrimary }}
						>
							{small_text}
						</h2>
					</div>
					<p className=" s:text-md text-base max-w-80">{big_text}</p>
				</div>
				
				<div className="flex items-center flex-wrap gap-4 px-4">
					<span
						className="text-2xl s:text-3xl xl:text-4xl  font-bold"
						style={{ color: Theme.TextPrimary }}
					>
						{formatPrice(real_price)} {t("EGP")}
					</span>{" "}
					<OldPrice className="text-xl s:text-2xl font-bold">
						{formatPrice(fake_price)} {t("EGP")}
					</OldPrice>
				</div>
				<div className=" text-lg flex justify-center">
					<Button type="round" style={{ background: Theme.TextPrimary }}>
						{t("ADD TO CART")}
						<EastOutlinedIcon sx={{ marginLeft: 1 }} />
					</Button>
				</div>
			</div>
			<div className="max-w-24 sm:max-w-none w-1/3 justify-center xl:w-1/2 flex grow">
				<LazyLoadImage
					className="max-h-full ml-2 rounded-xl"
					src={main_image}
					alt=""
				/>
			</div>
		</ProductContainer>
	);
}
