import { ReactComponent as LabelBg } from "../../../assets/images/label-background.svg";
import { LabelContainer } from "./label.style";

type props = {
	type: string;
	children: any;
};
export default function SecondLabel({ type, children }: props) {
	return (
		<LabelContainer className="scale-150">
			<span
				className={`${type} text-sm h-full w-full font-bold text-white text-right`}
			>
				<LabelBg />
				<p>{children}</p>
			</span>
		</LabelContainer>
	);
}
