import { DropdownContainer } from "./language.style";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import i18n from "../../../utils/i18n";
import { useState } from "react";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";
import Theme from "../../../utils/stylesTheme";
import { ReactComponent as ArabicLogo } from "../../../assets/icons/arabic.svg";
import { ReactComponent as EnglishLogo } from "../../../assets/icons/english.svg";

const Language = () => {
	const currentLanguage = i18n.language;
	const [language, setLanguage] = useState(
		currentLanguage === "ar" ? "العربية" : "English"
	);
	const [isOpen, setIsOpen] = useState(false);
	const queryClient = useQueryClient();
	window.addEventListener("click", (e) => {
		if (e.target !== document.querySelector(".dropbtn")) setIsOpen(false);
	});

	return (
		<DropdownContainer className="dropdown">
			<button className="dropbtn" onClick={() => setIsOpen(!isOpen)}>
				<LanguageOutlinedIcon />
				{language}
				<ExpandMoreOutlinedIcon />
			</button>
			<div
				className="dropdown-content flex flex-col"
				style={{ zIndex: "2000", display: isOpen ? "flex" : "none" }}
			>
				<motion.button
					whileHover={{ color: Theme.TextPrimary, scale: 1.1 }}
					className="w-full font-semibold text-xl p-2 flex items-center justify-between"
					onClick={() => {
						i18n.changeLanguage("ar");
						setLanguage("العربية");
						document.dir = "rtl";
						axios.defaults.headers.common["lang"] = "ar";
						queryClient.invalidateQueries();
						setIsOpen(false);
					}}
				>
					<ArabicLogo style={{ width: "25px", height: "25px" }} />
					العربية
				</motion.button>
				<motion.button
					whileHover={{ color: Theme.TextPrimary, scale: 1.1 }}
					className="w-full font-semibold text-xl p-2 flex items-center justify-between"
					onClick={() => {
						i18n.changeLanguage("en");
						setLanguage("English");
						document.dir = "ltr";
						axios.defaults.headers.common["lang"] = "en";
						queryClient.invalidateQueries();
						setIsOpen(false);
					}}
				>
					<EnglishLogo style={{ width: "25px", height: "25px" }} />
					English
				</motion.button>
			</div>
		</DropdownContainer>
	);
};

export default Language;
