import axios from "axios";
import SignUp from "../../routes/sign-up/sign-up.route";
import { Create, Remove, Subscript, Update } from "@mui/icons-material";
import { updateUserData } from "./updata-user-data";
import PrivacyPolicy from "../../routes/privacy-policy/privacy-policy.route";

const API_URL = "https://dashboard.resultgate.co/api/";
axios.defaults.baseURL = API_URL;
const END_POINTS = {
	AboutUs: "web/about",
	Address: "web/my/addresses",
	CreateAddress: "web/create/address",
	DeleteAddress: "web/delete/address",
	ContactUs: "web/contacts/send/message",
	Blogs: "web/blogs",
	Blog: "web/blogs/",
	AddBlogComment: "web/blog/comment",
	Home: "web/home",
	Teams: "all-teams",
	Team: "teams/",
	Terms: "web/terms",
	JoinTeam: "join-team",
	LeaveTeam: "team/delete-request/",
	Products: "web/all-products",
	Product: "web/product/",
	PrivacyPolicy: "web/privacy",
	Devices: "web/all-devices",
	Login: "web/login",
	SendRecoveryCode: "web/send/verify/code",
	categories: "web/categories",
	Profile: "web/get/profile",
	addToWishList: "web/action/wishlist",
	WishList: "web/wishlist",
	Countries: "web/countries",
	Cities: "web/cities/",
	Regions: "web/regions/",
	SubRegions: "web/subregions/",
	SignUp: "signup",
	VerifyEmail: "cheek-verification",
	ResendVerification: "verification-Resend",
	RecoveryEmail: "web/give-me-email",
	RecoveryCode: "web/check-forget-code",
	RestPassword: "web/change-password",
	Cart: "web/my/cart",
	AddToCart: "web/add/cart/item",
	RemoveFromCart: "web/delete/cart/item",
	UpdateCartItem: "web/update/cart/item",
	EmptyCart: "web/empty/cart",
	CheckPromoCode: "web/check/promo",
	Filter: "web/filter",
	Checkout: "web/checkout",
	Contact: "web/contacts-data",
	Orders: "web/my/orders",
	OrderDetails: "web/order/",
	CancelOrder: "web/cancel/order",
	Notifications: "notifications",
	DeleteNotification: "web/delete/notification",
	NotificationsSeen: "notifications-seen",
	UpdateUserData: "web/update/profile",
	search: "web/search",
	Subscription: "web/subscription/send",
	Faq: "web/faqs",
};

export default END_POINTS;
