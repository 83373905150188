import { styled } from "styled-components";
import defaultStyles from "../../../style/defaultStyles";
import { Link } from "react-router-dom";

export const LogoContainer = styled(Link)`
	display: flex;
	align-items: center;
	img {
		width: 60px;
	}
`;
export const BecomeAvendor = styled.a`
	padding: 0.5rem 1rem;
	border-radius: 5px;
	font-weight: bold;
	color: white;
	background-color: ${defaultStyles.primaryColor};
	display: flex;
	align-items: center;
`;
