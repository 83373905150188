import Button from "../../global/button/button.component";
import ProductImage from "../../../assets/images/product.png";
import RightArrowIcon from "../../../assets/icons/right-arrow-dark.svg";
import { SuggestionContainer } from "./suggestion-card-mini.style";
import SecondLabel from "../../global/second-label/second-label.component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Theme from "../../../utils/stylesTheme";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { useTranslation } from "react-i18next";
import ceilDiscount from "../../../utils/discountUtil";

export interface IcardData {
	big_text: string;
	link: string;
	main_image: string;
	small_text: string;
	discount: string;
}
export default function SuggestionCardMini({
	cardData,
}: {
	cardData: IcardData;
}) {
	const { t } = useTranslation();
	const { big_text, link, main_image, small_text, discount } = cardData;
	return (
		<SuggestionContainer
			href={link}
			className="col-span-12 row-span-2 md:col-span-6 2xl:col-span-3 row-sapn-3 flex items-center px-4 py-1 rounded-sm shadow gap-2 "
		>
			<div className="w-28 h-28 flex items-center justify-center">
				<LazyLoadImage
					className="max-h-full max-w-full"
					src={main_image}
					alt="product"
				/>
			</div>
			<div className="w-2/3 flex flex-col items-center gap-3">
				<p
					className="max-w-full truncate text-base font-semibold text-center whitespace-nowrap lg:whitespace-normal max-h-12"
					style={{ color: Theme.TextSecondary }}
				>
					{big_text}
				</p>
				<span className="hidden lg:block">
					{+discount && (
						<SecondLabel type="yellow">
							{+ceilDiscount(discount)}% {t("offer")}
						</SecondLabel>
					)}
				</span>
				<Button
					type="roundTransparent"
					style={{ borderColor: Theme.TextPrimary }}
				>
					{t("ADD TO CART")}
					<EastOutlinedIcon sx={{ marginLeft: 1 }} />
				</Button>
			</div>
		</SuggestionContainer>
	);
}
