import { ReactComponent as LabelBg } from "../../../assets/images/label-background.svg";
import { LabelContainer } from "./label.style";

type props = {
	type: string;
	children: string;
};

export default function Label({ type, children }: props) {
	return (
		<LabelContainer>
			<span
				className={`text-sm h-full w-full font-bold text-white text-right ${type}`}
			>
				<LabelBg />
				<p>{children}</p>
			</span>
		</LabelContainer>
	);
}
