import React from "react";
import ReactDOM from "react-dom/client";
import "typeface-montserrat";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./utils/i18n";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60,
		},
	},
});
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<PersistGate loading={null} persistor={persistor}>
						<HelmetProvider>
							<App />
						</HelmetProvider>
					</PersistGate>
				</BrowserRouter>
			</QueryClientProvider>
		</Provider>
	</React.StrictMode>
);
