import { Suspense, lazy } from "react";
import Home from "../../components/home/home.component";
import useTitleHook from "../../hooks/useTitleHook";

// const Home = lazy(() => import("../../components/home/home.component"));
export default function HomeRoute() {

	useTitleHook("Home");

	return (
		// <Suspense fallback={<h1>Loading...</h1>}>
		<Home />
		// </Suspense>
	);
}
