import Dropdown from "../dropdown/dropdown.component";
import NavigationLinks from "../navigation-links/navigation-links.component";
import SearchBox from "../search-box/search-box.component";
import ListIcon from "../../../assets/icons/list.svg";
import Notification from "../notification/notification.component";
import { useAppSelector } from "../../../store/store";
import Button from "../../global/button/button.component";
import { Link, useNavigate } from "react-router-dom";
import Theme from "../../../utils/stylesTheme";
import { useTranslation } from "react-i18next";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { IconButton } from "@mui/material";

export default function NavbarNavigations() {
	const currentUser = useAppSelector((state) => state.user.userData);
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div className="flex justify-between w-full gap-5 items-center pb-2">
			<Dropdown />
			<NavigationLinks />
			<SearchBox />
			{currentUser ? (
				<Notification />
			) : (
				<div className="flex items-center justify-between flex-wrap gap-2">
					{/* <Button
						breath={false}
						type="square"
						addClass="py-2 items-center"
						style={{ backgroundColor: Theme.TextPrimary }}
						onClick={() => navigate("/login")}
					>
						{t("Login")}
					</Button>
					<Button
						breath={false}
						type="squareTransparent"
						addClass="py-2  items-center shadow"
						style={{
							borderColor: Theme.BorderPrimary,
							color: Theme.TextPrimary,
						}}
						onClick={() => navigate("/sign-up")}
					>
						{t("Sign Up")}
					</Button> */}
					<Link to="/login">
						<PersonOutlineOutlinedIcon
							sx={{
								width: "30px",
								height: "30px",
								color: Theme.TextPrimary,
							}}
						/>
					</Link>
				</div>
			)}
		</div>
	);
}
